import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Carousel from 'react-material-ui-carousel'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import * as S from '../styles/workouts.styles'
import { WORKOUTS_SEE_ALL_ROUTE } from '../constants/routes'
import Layout from '../templates/Layout'
import WorkoutsRow from '../components/WorkoutsRow'
import * as session from '../services/session'
import { Section } from '../components/shared'
import { BACKGROUND } from '../constants/colors'
import { RootState } from '../redux/reducers/rootReducers'
import {
  onFetchWorkouts as onFetchWorkoutsAction,
  onFetchUserMetadata as onFetchUserMetadataAction,
} from '../redux/actions'
import { workoutsPublishedSelector } from '../redux/selectors'
import { GenericWorkout } from '../types/generic-workout'
import { withErrorHandler } from '../components/errorHandler'
import Filter, { FilterType } from '../components/filterVodWorkout'
import Chip from '@material-ui/core/Chip'
import { fetchAllTrainers } from '../util/fetch/trainers'
import { ITrainer } from '../types'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../constants/colors'

const Workouts: React.FC<Props> = (props: Props) => {
  session.redirectIfNotLoggedIn()
  const [trainers, setTrainers] = useState<ITrainer[]>([])

  const [filterValues, setFilterValues] = useState<FilterType>({
    category: null,
    trainer: null,
    length: null,
  })

  const { workouts, onFetchWorkouts, onFetchUserMetadata } = props

  useEffect(() => {
    onFetchWorkouts()
  }, [onFetchWorkouts])

  useEffect(() => {
    onFetchUserMetadata()
  }, [onFetchUserMetadata])

  useEffect(() => {
    const getTrainers = async () => {
      const response = await fetchAllTrainers()
      setTrainers(response)
    }
    getTrainers()
  }, [])

  const data = useStaticQuery(graphql`
    query {
      background1: file(id: { eq: "background-one" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      background2: file(id: { eq: "background-two" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      background3: file(id: { eq: "background-three" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const {
    background1: {
      childImageSharp: { fluid: bg1Fluid },
    },
    background2: {
      childImageSharp: { fluid: bg2Fluid },
    },
    background3: {
      childImageSharp: { fluid: bg3Fluid },
    },
  } = data

  const handleSeeAll = (type: string, genericWorkouts: GenericWorkout[]) => {
    navigate(WORKOUTS_SEE_ALL_ROUTE, {
      state: { type, workouts: genericWorkouts },
    })
  }

  /**
   * Aggregates filters and outputs remaining workouts
   * Default to all workouts being included since no filters are
   * present to start
   *
   * filteredData = resulting workouts to display
   */
  const filteredByCategory = workouts.filter(workout => {
    if (!filterValues.category) {
      return true
    } else {
      return filterValues.category.find(category => category === workout.type)
    }
  })

  const filteredByTrainers = filterValues.trainer
    ? filteredByCategory.map(w => ({
        ...w,
        workouts: w.workouts.filter(item =>
          filterValues.trainer.find(tr => Number(tr) === item.trainer.id),
        ),
      }))
    : filteredByCategory

  const filteredByLength = filterValues.length
    ? filteredByTrainers.map(w => ({
        ...w,
        workouts: w.workouts.filter(item =>
          filterValues.length.find(len => {
            const rangeArray = JSON.parse(len)
            return item.length >= rangeArray[0] && item.length <= rangeArray[1]
          }),
        ),
      }))
    : filteredByTrainers

  const filteredData = filteredByLength

  const onDeleteFilter = (key, value) => {
    const newFilterArray = filterValues[key]
    const index = newFilterArray.indexOf(value)
    if (index > -1) {
      newFilterArray.splice(index, 1)
    }
    setFilterValues({
      ...filterValues,
      [key]: newFilterArray.length === 0 ? null : newFilterArray,
    })
  }

  const formatChipLabel = (key, value) => {
    if (key === 'trainer') {
      const trainer = trainers.filter(tr => tr.id === value)[0]
      return `${trainer.firstName} ${trainer.lastName}`
    }
    if (key === 'length') {
      const rangeArray = JSON.parse(value)
      if (rangeArray[1] === 10000) {
        return '60+'
      }
      return `${rangeArray[0]} - ${rangeArray[1]}`
    }
    return value
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <div style={{ paddingTop: '1rem', backgroundColor: BACKGROUND }} />
        <Carousel interval={15000} navButtonsAlwaysInvisible={true}>
          <Img fluid={bg1Fluid} alt="background1" />
          <Img fluid={bg2Fluid} alt="background2" />
          <Img fluid={bg3Fluid} alt="background3" />
        </Carousel>

        <Section style={{ paddingTop: '2vh', paddingBottom: 40 }}>
          {Object.keys(filterValues).map((key, index) =>
            filterValues[key]
              ? filterValues[key].map(v => (
                  <Chip
                    key={v}
                    label={`${formatChipLabel(key, v)}`}
                    onDelete={() => onDeleteFilter(key, v)}
                    variant="default"
                    color="primary"
                    style={{ marginRight: 10, marginBottom: 20 }}
                    data-test={`appliedFilter${index}`}
                  />
                ))
              : [],
          )}
          <S.FilterWrap>
            <Filter
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              workouts={workouts}
              trainers={trainers}
            />
          </S.FilterWrap>
        </Section>

        {filteredData.map(
          (workout, index) =>
            workout.workouts.length > 0 && (
              <Section
                key={workout.type}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <S.WorkoutRow>
                  <S.SectionHeader>
                    <S.Title
                      data-test={`workoutCategory${index}`}
                    >{workout.type}</S.Title>
                    <S.SeeAll
                      data-test={`workoutSeeAll${index}`}
                      onClick={() =>
                        handleSeeAll(workout.type, workout.workouts)
                      }
                    >
                      See all
                    </S.SeeAll>
                  </S.SectionHeader>
                  <WorkoutsRow workouts={workout.workouts} />
                </S.WorkoutRow>
              </Section>
            ),
        )}
      </Layout>
    </ThemeProvider>
  )
}

const mapStateToProps = (state: RootState) => ({
  workouts: workoutsPublishedSelector(state),
  userMetadata: state.entities.userMetadataReducer.userMetadata,
})

const mapDispatchToProps = {
  onFetchWorkouts: onFetchWorkoutsAction.request,
  onFetchUserMetadata: onFetchUserMetadataAction.request,
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorHandler(Workouts))
