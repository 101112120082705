import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { ITrainer } from '../types'
import styled from 'styled-components'

export const TrainerImage = styled.img`
  width: 25px;
  height 25px;
  margin: 0;
  margin-right: 15px;
  border-radius: 50%;
`

export const TrainerRow = styled.div`
  display: flex;
  align-items: center;
`

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}))

export type FilterType = {
  category: string[] | null
  trainer: number[] | null
  length: string[] | null
}

type Props = {
  filterValues: FilterType
  setFilterValues: (values: FilterType) => void
  workouts: any
  trainers: ITrainer[]
}

export default function FilterVodWorkouts({
  filterValues,
  setFilterValues,
  workouts,
  trainers,
}: Props) {
  const classes = useStyles()

  const formatValue = (filter, value) => {
    if (value === null) {
      return null
    }
    return filter === null ? [value] : [...filter, value]
  }

  return (
    <div>
      <FormControl className={classes.margin} variant="outlined">
        <InputLabel id="demo-customized-select-label">Category</InputLabel>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value=""
          label="Category"
          style={{ minWidth: 120 }}
          data-test={'workoutsFilterCategory'}
          onChange={e =>
            setFilterValues({
              ...filterValues,
              category: formatValue(
                filterValues.category,
                e.target.value as any,
              ),
            })
          }
        >
          {workouts.map((w, i) => (
            <MenuItem
              key={w.type}
              value={w.type}
              data-test={`categoryOption${i}`}
            >
              {w.type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.margin} variant="outlined">
        <InputLabel id="demo-customized-select-label">Trainer</InputLabel>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value=""
          label="Trainer"
          style={{ minWidth: 100 }}
          data-test={'workoutsFilterTrainer'}
          onChange={e =>
            setFilterValues({
              ...filterValues,
              trainer: formatValue(filterValues.trainer, e.target.value as any),
            })
          }
        >
          {trainers.map((item, index) => (
            <MenuItem
              key={index}
              value={item.id}
              data-test={`trainerOption${index}`}
            >
              {item.firstName} {item.lastName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.margin} variant="outlined">
        <InputLabel id="demo-customized-select-label">Time</InputLabel>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value=""
          label="Time"
          style={{ minWidth: 100 }}
          data-test={'workoutsFilterTime'}
          onChange={e =>
            setFilterValues({
              ...filterValues,
              length: formatValue(filterValues.length, e.target.value as any),
            })
          }
        >
          <MenuItem value={'[0, 15]'} data-test={'timeOption0'}>
            0 - 15
          </MenuItem>
          <MenuItem value={'[15, 30]'}>15 - 30</MenuItem>
          <MenuItem value={'[30, 45]'}>30 - 45</MenuItem>
          <MenuItem value={'[45, 60]'}>45 - 60</MenuItem>
          <MenuItem value={'[60, 10000]'}>60+</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}
